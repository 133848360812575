import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = (props) => {

    // Add your own authentication on the below line.
    const role = sessionStorage.getItem("role");
    const customerRole = (role === "CUSTOMER") ? true : false;
    return (
        
        <div>
            {(customerRole)?<Route {...props}/>:
            <Redirect to="/" />}
        </div>
    )
}

export default PrivateRoute