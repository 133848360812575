import {
  AsyncMyProductsComponent,
  AsyncCartComponent,
  AsyncOrderComponent,
  AsyncInvoicesComponent,
  AsyncCheckOutComponent,
  AsyncContactUsComponent,
  ProductCategoryComponent,
  AsyncUpdatePasswordComponent,
  AllProuctsData,
  ProductDetailComponent,
  HomePageComponent,
  ProductsResultsComponent,
  SupplierComponent,
  UpdateSupplierComponent,
  AsyncOrderProductComponent,
  AsyncOrderManufactureComponent,
  AsyncNotificationsComponent,
  AsyncMyFavouritesComponent,
  AsyncMyShoppingListsComponent,
  AsyncPromotionComponent,
  AsyncRebateComponent,
  AsyncManufacturerAnalytics,
  AsyncPurchaseAnalytics,
  AsyncMerchantAnalytics,
  AsyncCategoryAnalytics,
  AsyncProductAnalytics,
  AsyncGroupAdmin,
  AsyncErrorPageComponent,
  PsivetTransaction,
  BudgetComponent,
  AddBudgetComponent,
  AsyncBackorderedComponent,
  AsyncPSIPromotionComponent,
  AsyncCorporateComponent,
  AsyncPracticeComponent,
  AsyncPSIVetSavingDetailsComponent,
  AsyncRebateSavingDetailsComponent,
  AsyncSavingDetailsComponent,
  AsyncGroupComponent,
  AsyncPracticeSignUpsComponent,
  PsivetPoints,
  AsyncPartnersComponent,
  AsyncTestComponent,
  AsyncPracticeDetailsComponent,
  AsyncProgramComponent,
  AsyncGroupReportsComponent,
  AsyncPointBasedProductPromotionComponent,
  AsyncCovetrusProgramComponent,
  AsyncVetscoutPromotionComponent,
  AsyncSessionGroupComponent,
  AsyncPracticeManufactureCategoryDetailsComponent,
  AsyncPracticeManufactureProductDetailsComponent,
  AsyncFAQComponent,
} from "./util/AsyncRoutes";

export const customerRoutes = [
  {
    path: "/products/myproducts",
    exact: true,
    component: AsyncMyProductsComponent,
  },
  {
    path: "/products/shopping-list",
    exact: true,
    component: AsyncMyShoppingListsComponent,
  },
  {
    path: ["/products", "/products/myfavorites"],
    exact: true,
    multiple: true,
    component: AsyncMyFavouritesComponent,
  },
  {
    path: "/promotions/exclusive",
    exact: true,
    component: AsyncPSIPromotionComponent,
  },
  {
    path: "/promotions/vetscout-exclusive",
    exact: true,
    component: AsyncVetscoutPromotionComponent,
  },
  {
    path: "/promotions/pointbasedproducts",
    exact: true,
    component: AsyncPointBasedProductPromotionComponent,
  },
  {
    path: "/rebates",
    exact: true,
    component: AsyncRebateComponent,
  },
  {
    path: "/products/categories",
    exact: true,
    component: AllProuctsData,
  },
  {
    path: "/productdetail",
    exact: true,
    component: ProductDetailComponent,
  },
  {
    path: "/homePage",
    exact: true,
    component: HomePageComponent,
  },
  {
    path: "/cart",
    exact: true,
    component: AsyncCartComponent,
  },
  {
    path: "/notifications",
    exact: true,
    component: AsyncNotificationsComponent,
  },
  {
    path: ["/orders", "/orders/history"],
    exact: true,
    multiple: true,
    component: AsyncOrderComponent,
  },
  {
    path: ["/orders", "/orders/invoices"],
    exact: true,
    multiple: true,
    component: AsyncInvoicesComponent,
  },
  {
    path: "/orders/backordered",
    exact: true,
    multiple: false,
    component: AsyncBackorderedComponent,
  },
  {
    path: ["/orders/products", "/products/purchaseHistory"],
    exact: true,
    multiple: true,
    component: AsyncOrderProductComponent,
  },
  {
    path: ["/orders/manufacturers", "/products/manufacturers"],
    exact: true,
    multiple: true,
    component: AsyncOrderManufactureComponent,
  },
  {
    path: "/check-out",
    exact: true,
    component: AsyncCheckOutComponent,
  },
  {
    path: ["/promotions", "/promotions/general"],
    exact: true,
    multiple: true,
    component: AsyncPromotionComponent,
  },
  {
    path: "/results/:value",
    exact: false,
    component: ProductsResultsComponent,
    keyword: "results",
  },
  {
    path: "/products/category",
    exact: true,
    component: ProductCategoryComponent,
  },
  {
    path: ["/analytics", "/analytics/merchant"],
    exact: true,
    multiple: true,
    component: AsyncMerchantAnalytics,
  },
  {
    path: "/analytics/purchase",
    exact: true,
    component: AsyncPurchaseAnalytics,
  },
  {
    path: "/analytics/manufacturer",
    exact: true,
    component: AsyncManufacturerAnalytics,
  },
  {
    path: "/analytics/category",
    exact: true,
    component: AsyncCategoryAnalytics,
  },
  {
    path: "/analytics/product",
    exact: true,
    component: AsyncProductAnalytics,
  },
  {
    path: "/addSupplier",
    exact: true,
    component: SupplierComponent,
  },
  {
    path: "/updateSupplierDetails",
    exact: true,
    component: UpdateSupplierComponent,
  },
  {
    path: "/update-password",
    exact: true,
    component: AsyncUpdatePasswordComponent,
  },
  // {
  //     path: "/userProfile",
  //     exact: false,
  //     component: AsyncUserProfileComponent
  // },
  {
    path: "/contact-us",
    exact: true,
    component: AsyncContactUsComponent,
  },
  {
    path: "/error",
    exact: true,
    component: AsyncErrorPageComponent,
  },
  {
    path: "/transaction",
    exact: true,
    component: PsivetTransaction,
  },
  {
    path: "/orders/budgets",
    exact: true,
    component: BudgetComponent,
  },
  {
    path: "/orders/budgets/add",
    exact: true,
    component: AddBudgetComponent,
  },
  {
    path: "/corporate/home",
    exact: true,
    component: AsyncCorporateComponent,
  },
  {
    path: "/dashboard/practice",
    exact: true,
    component: AsyncPracticeDetailsComponent,
  },
  {
    path: "/practices",
    exact: true,
    component: AsyncPracticeComponent,
  },
  {
    path: ["/savings", "/rebates/savings"],
    exact: true,
    multiple: true,
    component: AsyncSavingDetailsComponent,
  },
  {
    path: "/rebate-savings",
    exact: true,
    component: AsyncRebateSavingDetailsComponent,
  },
  {
    path: "/psivet-savings",
    exact: true,
    component: AsyncPSIVetSavingDetailsComponent,
  },
  {
    path: "/group",
    exact: true,
    component: AsyncGroupComponent,
  },
  {
    path: "/session/group",
    exact: true,
    component: AsyncSessionGroupComponent,
  },
  {
    path: "/sign-ups",
    exact: true,
    component: AsyncPracticeSignUpsComponent,
  },
  {
    path: "/points",
    exact: true,
    component: PsivetPoints,
  },
  {
    path: "/partners",
    exact: true,
    component: AsyncPartnersComponent,
  },
  {
    path: "/test-user",
    exact: true,
    component: AsyncTestComponent,
  },
  {
    path: "/rebates/program",
    exact: false,
    component: AsyncProgramComponent,
  },
  {
    path: "/program/covetrus",
    exact: true,
    component: AsyncCovetrusProgramComponent,
  },
  {
    path: "/reports",
    exact: true,
    component: AsyncGroupReportsComponent,
  },
  {
    path: "/category-manufacturer-report",
    exact: true,
    component: AsyncPracticeManufactureCategoryDetailsComponent,
  },
  {
    path: "/manufacturer-product-report",
    exact: true,
    component: AsyncPracticeManufactureProductDetailsComponent,
  },
  {
    path: "/faq",
    exact: true,
    component: AsyncFAQComponent,
  },
];

export const groupAreaManagerRoutes = [
  {
    path: "/rebates",
    exact: true,
    component: AsyncRebateComponent,
  },
  {
    path: ["/analytics", "/analytics/merchant"],
    exact: true,
    multiple: true,
    component: AsyncMerchantAnalytics,
  },
  {
    path: "/analytics/purchase",
    exact: true,
    component: AsyncPurchaseAnalytics,
  },
  {},
  {
    path: "/analytics/manufacturer",
    exact: true,
    component: AsyncManufacturerAnalytics,
  },
  {
    path: "/analytics/category",
    exact: true,
    component: AsyncCategoryAnalytics,
  },
  {
    path: "/analytics/product",
    exact: true,
    component: AsyncProductAnalytics,
  },
  {
    path: "/update-password",
    exact: true,
    component: AsyncUpdatePasswordComponent,
  },
  {
    path: "/contact-us",
    exact: true,
    component: AsyncContactUsComponent,
  },
  {
    path: "/error",
    exact: true,
    component: AsyncErrorPageComponent,
  },
  {
    path: "/practices",
    exact: true,
    component: AsyncPracticeComponent,
  },
  {
    path: "/sign-ups",
    exact: true,
    component: AsyncPracticeSignUpsComponent,
  },
  {
    path: "/points",
    exact: true,
    component: PsivetPoints,
  },
  {
    path: "/practice",
    exact: true,
    component: AsyncPracticeDetailsComponent,
  },
  {
    path: "/reports",
    exact: true,
    component: AsyncGroupReportsComponent,
  },
  {
    path: "/faq",
    exact: true,
    component: AsyncFAQComponent,
  },
];

export const supportRoutes = [
  {
    path: "/group-admin",
    exact: true,
    component: AsyncGroupAdmin,
  },
];
